import axios from 'axios';
import { getCredentials } from "../auth/authUtils";
import { getDataFromCacheOrFetchLocalStorage } from './cacheServices';

export const getAllActivities = async (sort = true) => {
  const cacheKey = "getAllActivities"; // Define la clave para el caché de getAllActivities
  const fetchFunction = async () => {
    const response:any = await axios(`${process.env.REACT_APP_BACKEND_DOMAIN}?action=activities&${getCredentials()}`);
    if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
    let data = await response.data.content;
    if(sort)data =  data.sort((a:any, b:any) => new Date(b).getTime() - new Date(a).getTime())
    return data;
  };

  return getDataFromCacheOrFetchLocalStorage(cacheKey, fetchFunction);
};
