
export default function NotFound() {
    return (
        <main>
            <div className="main-container row justify-content-around mx-5 flex-xs-row-reverse   ">
                <div className="col-12 col-md-6 col-xl-4">
                    <h1>Ups... Tengo algo que contarte</h1>
                    <h2 style={{ color: 'red' }}>La página que estás buscando <b>no existe</b>.</h2>
                    <h5>Error 404</h5>
                </div>
            </div>
        </main>
    )
}
