import { HTMLProps, ReactNode, useEffect, useState } from 'react';
import { Button, Collapse, Row } from 'reactstrap';

interface SectionContainerProps extends HTMLProps<HTMLDivElement> {
  title: string;
  children: ReactNode; 
  classNameContainer?: string; 
  className?: string; 
  error?: string; 
  isCollapsed?: boolean; // Prop opcional para recibir el valor del estado colapsado
  setIsCollapsed?: any; // Prop opcional para manejar el colapso
}

export default function SectionContainer({
  title,
  children,
  className,
  classNameContainer,
  error,
  isCollapsed: propIsCollapsed, // Renombramos la prop para evitar conflicto con el estado local
  setIsCollapsed: propSetIsCollapsed, // Renombramos la prop para evitar conflicto con el estado local
}: SectionContainerProps) {
  const [isCollapsedLocal, setIsCollapsedLocal] = useState(
    propIsCollapsed ? propIsCollapsed : false
  );
  const [windowWidth,setWindowWidth] = useState(window.innerWidth)

  const toggleCollapse = () => {
    setIsCollapsedLocal(!isCollapsedLocal);
    if (propSetIsCollapsed) {
      propSetIsCollapsed(); // Llama a la función proporcionada para manejar el colapso si existe
    }
  };
  // Agregar un efecto para actualizar windowWidth en redimensionamientos
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Suscribirse al evento de redimensionamiento
    window.addEventListener('resize', handleResize);

    // Desuscribirse del evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className={`container-responsive my-3 ${className}`}>
      <Button
        color=""
        onClick={toggleCollapse}
        className={`d-lg-none secctionColapsedBtn col-12 col-md-4  ${
          !isCollapsedLocal ? 'secctionBtnNoColapsed' : ''
        }`}
      >
        <h4>
          {title}{' '}
          {isCollapsedLocal ? (
            <i className="fas fa-chevron-up" />
          ) : (
            <i className="fas fa-chevron-down" />
          )}
        </h4>
      </Button>
      <Collapse isOpen={isCollapsedLocal || !(windowWidth < 992)} >
        <h3 className="d-none d-lg-block marginCustomeTitleCollapse">{title}</h3>
        <Row className={`border p-3 ${classNameContainer}`} >
          {!error ? children : <p className="fs-4 m-auto text-center">{error}</p>}
        </Row>
      </Collapse>
    </div>
  );
}
