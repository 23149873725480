import axios from "axios";
import { useEffect, useState } from "react";
import {
	Alert,
	Button,
	FormGroup,
	Input,
	Label,
	ModalBody,
	ModalHeader
} from "reactstrap";
import { getCredentials } from "../../../auth/authUtils";
import { getAllGroups } from "../../../services/groupService";

export default function DownloadContacts(props: any) {
	const [error, setError] = useState('');
	const [isDownloading, setIsDownloading] = useState(false);
	const [groups, setGroups] = useState<string[]>([]);
	const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
	useEffect(() => {
		(async () => {
			setGroups((await getAllGroups()).map(x => x.name))
		})()
	}, [])
	const download = async (_e: any) => {
		setIsDownloading(true);
		const response = await axios(`${process.env.REACT_APP_BACKEND_DOMAIN}?action=contacts${selectedGroups.length ? '&group=' + selectedGroups.join(',') : ''}&${getCredentials()}`);
		if (response.status === 200) {
			const a = document.createElement('a');
			a.download = response.headers?.['Content-Disposition']?.split(' filename*=UTF-8\'\'')?.[1] || 'Contactos de grupos.csv';
			a.setAttribute('target', '_blank');
			a.href = URL.createObjectURL(new Blob([response.data], { type: String(response.headers?.['Content-Type']) || 'text/csv;charset=utf8' }));
			a.click();
			a.href = '';
		} else {
			try {
				setError(JSON.parse(response.data)?.message || 'Error desconocido');
			} catch (err) {
				setError('Error desconocido');
			}
		}
		setIsDownloading(false);
	}

	return (<>
		<ModalHeader toggle={() => props.toggle()} charcode="close">
			Descargar Contactos
		</ModalHeader>
		<ModalBody>
			{error && <Alert color="danger">{ }</Alert>}
			<p>
				Seleccioná los grupos para incluir en el archivo de contactos. Si no se marca ninguno, se van a incluir todos.
			</p>
			{groups.map(group => (<FormGroup check inline key={group}>
				<Input type="checkbox" id={`download.${group}`} checked={selectedGroups.includes(group)} onChange={(e) => {
					if (e.target.checked) setSelectedGroups([...selectedGroups, group]);
					else setSelectedGroups(selectedGroups.filter(x => x !== group))
				}} />
				<Label htmlFor={`download.${group}`}>{group}</Label>
			</FormGroup>))}
			<p>
				<Button color="primary" onClick={download} style={{ width: '100%' }} disabled={isDownloading}>Crear y Descargar</Button>
			</p>
			<div className="accordion">
				<button
					className="accordion-button collapsed"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target={`#collapseHelp`}
					aria-expanded="false"
					aria-controls={`collapseHelp`}
				>
					<span>Ayuda para importar</span>
				</button>
				<div className="collapse" id={`collapseHelp`}>
					<div className="card card-body">
						<p>
							Para cargar los contactos en el celular (tanto Android como iPhone), lo mejor es cargarlos online desde Google Contacts y sincronizar la cuenta a la que se los agregó en el celular. Algunos links de referencia:
							<br />
							<a href="https://support.google.com/contacts/answer/15147365?hl=es">Cómo importar contactos</a>
							<br />
							<a href="https://support.google.com/contacts/answer/2753077?hl=es&co=GENIE.Platform%3DiOS&oco=2">iPhone: Añadir cuenta para sincronizar contactos</a>
							<br />
							<a href="https://support.google.com/contacts/answer/2753077?hl=es&co=GENIE.Platform%3DAndroid&oco=2">Android: Añadir cuenta para sincronizar contactos</a>
						</p>
						<p>
							Para actualizar contactos cargados previamente, se deben borrar los grupos que se quieran actualizar desde la parte de etiquetas de Google Contactos y después cargar un archivo nuevo.
						</p>
					</div>
				</div>
			</div>
		</ModalBody>
	</>);
}
