import JanijListDTO from "../dtos/JanijListDTO";

export const filterActive = (tableFilter: string, item: JanijListDTO) =>{
    return (tableFilter === 'Todos' || (
        (tableFilter === 'Activos' && item.active) || 
        (tableFilter === 'Inactivos' && !item.active && item.common) || 
        (tableFilter === 'Archivados' && !item.active && !item.common) || 
        (tableFilter === 'Nuevos' && item.active && item.isNew) 
    ))
}
