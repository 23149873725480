import { useEffect, useState } from "react";
import {
	Alert,
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Spinner,
} from "reactstrap";
import GroupDTO from "../../../../dtos/GroupDTO";
import JanijDTO from "../../../../dtos/JanijDTO";
import { addJanij } from "../../../../services/janijService";
import { getAddJanijData } from "../../../../services/viewService";
import preventReload from "../../../../utils/preventReload";
import {
	isEmptyOrSpaces,
} from "../../../../utils/strings";
import CreatableSelectSearch from "../../Selects/CreatableSelect";

function AddJanijBody(props: any) {
  const attendanceData = props.attendanceData;
  let initialFieldsState: JanijDTO = {
    name: "",
    group: (attendanceData)?attendanceData.group.replace("%20"," "):"",
    birthday: "",
    nationalId: "",
    address: "",
    email: "",
    cellphone: "",
    school: "",
    notes: "",
    mother: {
      name: "",
      cellphone: "",
      email: "",
    },
    father: {
      name: "",
      cellphone: "",
      email: "",
    },
    
  };
  if(attendanceData){
    const year = parseInt(attendanceData.date.split('-')[0]);
    const month = parseInt(attendanceData.date.split('-')[1]);
    const day = parseInt(attendanceData.date.split('-')[2]);
    initialFieldsState = {
      ...initialFieldsState,
	  group: attendanceData.group.replace("%20"," "),
      attendances: [{
        year,
        month,
        day,
        attended: true,
      }],
    }
             
  }
  const [fields, setFields] = useState<JanijDTO[]>([initialFieldsState]);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [viewData, setViewData] = useState<any>();
  const [actualView, setActualView] = useState(0);
  const [showFullData, setShowFullData] = useState(false);

  useEffect(preventReload, [])

  async function fetchData() {
    setLoaded(false);
    setViewData(await getAddJanijData());

    setLoaded(true);
  }
  const agregarHandleChange = (e: any) => {
    const newFields = () => {
      setFields((prevState) => {
        const newFields = [...prevState, initialFieldsState];
        return newFields;
      });
    };

    if (e.target.name === "nuevo") {
      newFields();
      setActualView(actualView + 1);
    }

    if (e.target.name === "anterior") {
      if (actualView > 0) {
        setActualView(actualView - 1);
      }
    }
    if (e.target.name === "siguiente") {
      if (fields.length < actualView + 2) {
        newFields();
      }
      setActualView(actualView + 1);
    }
  };
  const addHandleChange = (e: any) => {
    setError(false);
    if (e.label) {
      const [actualV, fieldName] = [actualView, "name"];
      const value = e.label;
      setFields((prevState) => {
        const updatedFields = [...prevState];
        updatedFields[actualV] = {
          ...updatedFields[actualV],
          [fieldName]: value,
        };
        return updatedFields;
      });
      return;
    }

    const { name, value } = e.target;
    const [actualV, fieldName, subFieldName] = name.split(".");

    if (subFieldName) {
      setFields((prevState) => {
        const updatedFields = [...prevState];
        updatedFields[actualV] = {
          ...updatedFields[actualV],
          [fieldName]: {
            ...(updatedFields[actualV] as any)[fieldName],
            [subFieldName]: value,
          },
        };
        return updatedFields;
      });
    } else {
      setFields((prevState) => {
        const updatedFields = [...prevState];
        updatedFields[actualV] = {
          ...updatedFields[actualV],
          [fieldName]: value,
        };
        return updatedFields;
      });
    }
  };

  const addJanijInBackground = async () => {
    try {
      await addJanij(fields);
	  props.avisoAlert({
        show: true,
        message: `Se agregó correctamente`,
        status: "succes",
      });
      props.refresh();
    } catch (error:any) {
      console.error("err",error);
      props.avisoAlert({
        show: true,
        message: 'Error:',
        extraMessage: error.message,
        status: "danger",
      },5000);
    } finally {
      setTimeout(() => props.avisoAlert({show:false,message:"",status:"warning",extraMessage:null}), 5000);
    }
  };

 

  const postRequest = async () => {
    setError(false);

    for (const field of fields) {

      if (isEmptyOrSpaces(field.name) || !field.group) {
        setError(true);
        console.error("ERROR,No se ingresaron los datos necesarios");
        return;
      }
    }

    if (!error) {
      setIsAdding(true);
      addJanijInBackground();
      props.avisoAlert({
        show: true,
        message: `Agregando Janij`,
        status: "warning",
        extraMessage: <Spinner animation="border" variant="light" size="sm" />,
      });
      setIsAdding(false);
      props.toggle();
    }
  };

  const handleChangeInputSearch = (e: any) => {
    setError(false);
    if (e) {
      const [actualV, fieldName] = [actualView, "name"];
      const value = e.label ? e.label : e;
      setFields((prevState) => {
        const updatedFields = [...prevState];
        updatedFields[actualV] = {
          ...updatedFields[actualV],
          [fieldName]: value,
        };
        return updatedFields;
      });
      return;
    }
  };
  const  handleChangeAttendance= (e:any)=>{
   
    const { name } = e.target;
    const [actualV, fieldName, subFieldName] = name.split(".");

    if (subFieldName) {
      setFields((prevState) => {
        const updatedFields = [...prevState];
        updatedFields[actualV] = {
          ...updatedFields[actualV],
          [fieldName]: {
            ...(updatedFields[actualV] as any)[fieldName],
            [subFieldName]: !fields[actualV].attendances?.[0]?.attended,
          },
        };
        return updatedFields;
      });
    }
  }
  const toggleCancelAddModal = () => {
    setError(false);
    props.toggle();
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ModalHeader toggle={toggleCancelAddModal} charcode="close">
        {props.title} Janij {fields.length === 1 ? "" : ` | ${actualView + 1}`}
      </ModalHeader>
      <ModalBody>
        {error && <Alert color="danger">Error! Datos incorrectos</Alert>}
        <Form>
          <FormGroup>
            <Label htmlFor={`${actualView}.name`}>Nombre y Apellido</Label>
            <CreatableSelectSearch
              data={
                loaded && viewData && viewData["janijim"]
                  ? viewData["janijim"]
                  : []
              }
              disabled={
                !(
                  loaded &&
                  viewData &&
                  viewData["groups"] &&
                  viewData["janijim"]
                ) || isAdding
              }
              display="name"
              label="Nuevo Janij:"
              id={`${actualView}.name`}
              name={`${actualView}.name`}
              className="mb-3"
              defaultValue={fields[actualView].name}
              onChange={handleChangeInputSearch}
              placeholder={
                loaded && viewData && viewData["janijim"]
                  ? "Escribe nombre"
                  : "Cargando..."
              }
              key={actualView} //al cambiar la key, vuelve a renderizarce y se pone el defaultValue.
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor={`${actualView}.group`}>Grupo</Label>
            <Input
              id={`${actualView}.group`}
              name={`${actualView}.group`}
              className="mb-3"
              type="select"
              onChange={addHandleChange}
              value={fields[actualView].group ? fields[actualView].group : "-1"}
              disabled={!(loaded && viewData && viewData["groups"]) || isAdding}
            >
              {!(loaded && viewData && viewData["groups"]) && (
                <option disabled>
                  Cargando...
                </option>
              )}
              {loaded && (
                <option key="-1" value="-1" disabled>
                  Elija un grupo
                </option>
              )}
              {loaded &&
                viewData &&
                viewData["groups"].map((group: GroupDTO) => (
                  <option key={group.name} value={group.name}>
                    {group.name}
                  </option>
                ))}
            </Input>
          </FormGroup>
          {fields[actualView].attendances &&
          <FormGroup>
            <Label htmlFor={`${actualView}.attendance.attended`}>Marcar como presente</Label>
            <Input
             id={`${actualView}.attendance.attended`}
             name={`${actualView}.attendance.attended`}
              type="checkbox"
              color="danger"
              className="ms-2"
              onChange={handleChangeAttendance}
              disabled={!(loaded && viewData && viewData["groups"]) || isAdding}
              checked={fields[actualView].attendances?.[0]?.attended}
            />
          </FormGroup>
          }
          <FormGroup>
            <Label htmlFor="showFullData">Todos los datos</Label>
            <Input
              id="showFullData"
              type="checkbox"
              name="showFullData"
              color="danger"
              className="ms-2"
              onChange={() => setShowFullData(!showFullData)}
              disabled={!(loaded && viewData && viewData["groups"]) || isAdding}
              checked={showFullData}
            />
          </FormGroup>
          {/* DATOS ADICIONALES */}
          {showFullData ? (
            <>
              <FormGroup>
                <Label htmlFor={`${actualView}.birthday`}>Cumpleaños</Label>
                <Input
                  id={`${actualView}.birthday`}
                  disabled={
                    !(loaded && viewData && viewData["groups"]) || isAdding
                  }
                  name={`${actualView}.birthday`}
                  type="date"
                  onChange={addHandleChange}
                  value={fields[actualView].birthday}
                  autoComplete="off"
                  placeholder={
                    !(loaded && viewData && viewData["groups"])
                      ? "Cargando..."
                      : ""
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor={`${actualView}.address`}>Dirección</Label>
                <Input
                  id={`${actualView}.address`}
                  disabled={
                    !(loaded && viewData && viewData["groups"]) || isAdding
                  }
                  name={`${actualView}.address`}
                  onChange={addHandleChange}
                  value={fields[actualView].address}
                  autoComplete="off"
                  placeholder={
                    !(loaded && viewData && viewData["groups"])
                      ? "Cargando..."
                      : ""
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor={`${actualView}.school`}>Escuela</Label>
                <Input
                  id={`${actualView}.school`}
                  disabled={
                    !(loaded && viewData && viewData["groups"]) || isAdding
                  }
                  name={`${actualView}.school`}
                  onChange={addHandleChange}
                  value={fields[actualView].school}
                  autoComplete="off"
                  placeholder={
                    !(loaded && viewData && viewData["groups"])
                      ? "Cargando..."
                      : ""
                  }
                />
              </FormGroup>
              <div className="accordion">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseContact`}
                  aria-expanded="false"
                  aria-controls={`collapseContact`}
                >
                  <span>Datos de Contacto</span>
                </button>
                <div className="collapse" id={`collapseContact`}>
                  <div className="card card-body">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapseMadre`}
                      aria-expanded="false"
                      aria-controls={`collapseMadre`}
                    >
                      <span>Madre</span>
                    </button>
                    <div className="collapse" id={`collapseMadre`}>
                      <div className="card card-body">
                        <FormGroup>
                          <Label htmlFor={`${actualView}.mother.name`}>
                            Nombre
                          </Label>
                          <Input
                            id={`${actualView}.father.name`}
                            disabled={
                              !(loaded && viewData && viewData["groups"]) ||
                              isAdding
                            }
                            name={`${actualView}.mother.name`}
                            onChange={addHandleChange}
                            value={fields[actualView].mother?.name}
                            autoComplete="off"
                            placeholder={
                              !(loaded && viewData && viewData["groups"])
                                ? "Cargando..."
                                : ""
                            }
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor={`${actualView}.mother.cellphone`}>
                            Celular
                          </Label>
                          <Input
                            id={`${actualView}.mother.cellphone`}
                            disabled={
                              !(loaded && viewData && viewData["groups"]) ||
                              isAdding
                            }
							type="tel"
                            name={`${actualView}.mother.cellphone`}
                            onChange={addHandleChange}
                            value={fields[actualView].mother?.cellphone}
                            autoComplete="off"
                            placeholder={
                              !(loaded && viewData && viewData["groups"])
                                ? "Cargando..."
                                : ""
                            }
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor={`${actualView}.mother.email`}>
                            Email
                          </Label>
                          <Input
                            id={`${actualView}.mother.email`}
                            disabled={
                              !(loaded && viewData && viewData["groups"]) ||
                              isAdding
                            }
							type="email"
                            name={`${actualView}.mother.email`}
                            onChange={addHandleChange}
                            value={fields[actualView].mother?.email}
                            autoComplete="off"
                            placeholder={
                              !(loaded && viewData && viewData["groups"])
                                ? "Cargando..."
                                : ""
                            }
                          />
                        </FormGroup>
                      </div>
                    </div>

                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapsePadre`}
                      aria-expanded="false"
                      aria-controls={`collapsePadre`}
                    >
                      <span>Padre</span>
                    </button>
                    <div className="collapse" id={`collapsePadre`}>
                      <div className="card card-body">
                        <FormGroup>
                          <Label htmlFor={`${actualView}.father.name`}>
                            Nombre
                          </Label>
                          <Input
                            id={`${actualView}.father.name`}
                            disabled={
                              !(loaded && viewData && viewData["groups"]) ||
                              isAdding
                            }
                            name={`${actualView}.father.name`}
                            onChange={addHandleChange}
                            value={fields[actualView].father?.name}
                            autoComplete="off"
                            placeholder={
                              !(loaded && viewData && viewData["groups"])
                                ? "Cargando..."
                                : ""
                            }
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor={`${actualView}.father.cellphone`}>
                            Celular
                          </Label>
                          <Input
                            id={`${actualView}.father.cellphone`}
                            disabled={
                              !(loaded && viewData && viewData["groups"]) ||
                              isAdding
                            }
							type="tel"
                            name={`${actualView}.father.cellphone`}
                            onChange={addHandleChange}
                            value={fields[actualView].father?.cellphone}
                            autoComplete="off"
                            placeholder={
                              !(loaded && viewData && viewData["groups"])
                                ? "Cargando..."
                                : ""
                            }
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor={`${actualView}.father.email`}>
                            Email
                          </Label>
                          <Input
                            id={`${actualView}.father.email`}
                            disabled={
                              !(loaded && viewData && viewData["groups"]) ||
                              isAdding
                            }
							type="email"
							name={`${actualView}.father.email`}
                            onChange={addHandleChange}
                            value={fields[actualView].father?.email}
                            autoComplete="off"
                            placeholder={
                              !(loaded && viewData && viewData["groups"])
                                ? "Cargando..."
                                : ""
                            }
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseAdditional`}
                  aria-expanded="false"
                  aria-controls={`collapseAdditional`}
                >
                  <span>Datos Adicionales</span>
                </button>
                <div className="collapse" id={`collapseAdditional`}>
                  <div className="card card-body">
                    <FormGroup>
                      <Label htmlFor={`${actualView}.nationalId`}>DNI</Label>
                      <Input
                        id={`${actualView}.nationalId`}
                        disabled={
                          !(loaded && viewData && viewData["groups"]) ||
                          isAdding
                        }
						type="number"
                        name={`${actualView}.nationalId`}
                        onChange={addHandleChange}
                        value={fields[actualView].nationalId}
                        autoComplete="off"
                        placeholder={
                          !(loaded && viewData && viewData["groups"])
                            ? "Cargando..."
                            : ""
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor={`${actualView}.email`}>Email Janij</Label>
                      <Input
                        id={`${actualView}.email`}
                        disabled={
                          !(loaded && viewData && viewData["groups"]) ||
                          isAdding
                        }
						type="email"
						name={`${actualView}.email`}
                        onChange={addHandleChange}
                        value={fields[actualView].email}
                        autoComplete="off"
                        placeholder={
                          !(loaded && viewData && viewData["groups"])
                            ? "Cargando..."
                            : ""
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor={`${actualView}.cellphone`}>
                        Celular Janij
                      </Label>
                      <Input
                        id={`${actualView}.cellphone`}
                        disabled={
                          !(loaded && viewData && viewData["groups"]) ||
                          isAdding
                        }
						type="tel"
                        name={`${actualView}.cellphone`}
                        onChange={addHandleChange}
                        value={fields[actualView].cellphone}
                        autoComplete="off"
                        placeholder={
                          !(loaded && viewData && viewData["groups"])
                            ? "Cargando..."
                            : ""
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor={`${actualView}.notes`}>Notas</Label>
                      <Input
                        id={`${actualView}.notes`}
                        disabled={
                          !(loaded && viewData && viewData["groups"]) ||
                          isAdding
                        }
						type="textarea"
                        name={`${actualView}.notes`}
                        onChange={addHandleChange}
                        value={fields[actualView].notes}
                        autoComplete="off"
                        placeholder={
                          !(loaded && viewData && viewData["groups"])
                            ? "Cargando..."
                            : ""
                        }
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          <ModalFooter>
            {fields.length === 1 ? (
              <Button
                color="primary"
                name="nuevo"
                onClick={agregarHandleChange}
                disabled={isAdding}
              >
                Agregar más
              </Button>
            ) : (
              <>
                <Button
                  color="primary"
                  name="anterior"
                  onClick={agregarHandleChange}
                  disabled={isAdding}
                >
                  &laquo;
                </Button>
                <h3>{actualView + 1}</h3>
                <Button
                  color="primary"
                  name="siguiente"
                  onClick={agregarHandleChange}
                  disabled={isAdding}
                >
                  &raquo;
                </Button>
              </>
            )}

            <Button onClick={toggleCancelAddModal} disabled={isAdding}>
              Cancelar
            </Button>
            <Button
              color={isAdding ? "success" : "danger"}
              disabled={!(loaded && viewData && viewData["groups"]) || isAdding}
              onClick={postRequest}
            >
              {isAdding ? (
                <div>
                  Guardando...{" "}
                  <Spinner animation="border" variant="light" size="sm" />
                </div>
              ) : (
                props.title
              )}
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </>
  );
}

export default AddJanijBody;
