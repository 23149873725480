
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Collapse, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import HeaderPage from '../components/UI/Layout/HeaderPage';
import SectionContainer from '../components/UI/Layout/SectionContainer';
import ViewAttendances from '../components/UI/Layout/ViewAttendances';
import GroupDTO from '../dtos/GroupDTO';
import JanijDTO from '../dtos/JanijDTO';
import { getAttendanceByNames } from '../services/attendanceService';
import { updateJanij } from '../services/janijService';
import { getMessageDebtByName } from '../services/paymentsService';
import { getEditJanijData } from '../services/viewService';
import { compareObjects } from '../utils/comparisons';
import { formatDateMonthUsToStringMonthEs, formatDateToUsMonthYear, formatDateUsToEs } from '../utils/dates';
import { isEmptyOrSpaces } from '../utils/strings';

interface FormGroupCustomProps {
  name: string;
  label: string;
  value?: string;
  type?: any;
  onChange: any;
  col: string;
  props: any; 
  optionsSelect?:any[];
  children?: any;
  style?: any;
}


function FormGroupCustom({name,label,value,type='text',children,onChange,col,style,props}:FormGroupCustomProps){
  return(
    <FormGroup className={col}>
        <Label htmlFor={name}>{label}</Label>
        <Input
          id={name}
          type={type}
          disabled={
            !(
              props.loaded &&
              props.viewDataPersonalDates &&
              props.viewDataPersonalDates["groups"] &&
              props.viewDataPersonalDates["janijData"] &&
              props.janijIsEditable
            ) ||
            props.isUpdating ||
            !props.firstLoad
          }
          name={name}
          onChange={onChange}
          autoComplete="off"
          placeholder={
            !(
              props.loaded &&
              props.viewDataPersonalDates &&
              props.viewDataPersonalDates["groups"] &&
              props.viewDataPersonalDates["janijData"]
            )
              ? "Cargando..."
              : ""
          }
          value={props.viewDataPersonalDates["janijData"] && props.loaded &&value?value:""}
          style={style}
        >
          {children}
        </Input>
      </FormGroup>
  )
}



export default function JanijDetails(props:any) {
    const navigate = useNavigate();
    const { janijName } = useParams();
    const [errorMessagePagos, setErrorMessagePagos] = useState('')
    const [loaded, setLoaded] = useState(false);
    const [loadedMessagePayments, setLoadedMessagePayments] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [notEditedFields, setNotEditedFields] = useState<any>();
    const [errorDatosPersonales, setErrorDatosPersonales] = useState('');
    const [errorAsistencia, setErrorAsistencia] = useState('');
    const [errorPagos, setErrorPagos] = useState('');
    const [janijIsEditable, setJanijIsEditable] = useState(false);
    const [viewDataPersonalDates, setViewDataPersonalDates] = useState<any>([null]);
    const [viewDataAttendances, setViewDataAttendances] = useState<any>(null);
    const [viewDataPayments, setViewDataPayments] = useState<any>([null]);
    const [firstLoad, setFirstLoad] = useState(false);
    const handleAvisoAlert = props.handleAvisoAlert;
    const [isOpenColapseMother, setIsOpenColapseMother] = useState(false);
    const [isOpenColapseFather, setIsOpenColapseFather] = useState(false);
    const [isCollapsedAttendances, setIsCollapsedAttendances] = useState(false);
    const today = new Date();
    const initialDatesPayments = {from:`${today.getFullYear()}-02`,to:formatDateToUsMonthYear(today),type:'Deuda'}
    const [paymentsDatas, setPaymentsDatas] = useState(initialDatesPayments);
    const [windowWidth,setWindowWidth] = useState(window.innerWidth)

    const toggleCollapseMother = () => {setIsOpenColapseMother(!isOpenColapseMother)};
    const toggleCollapseFather = () => {setIsOpenColapseFather(!isOpenColapseFather)};
    
    const toggleJanijIsEditable = ()=>{
		setJanijIsEditable(!janijIsEditable)
		window.onbeforeunload = janijIsEditable ? null : () => "";
	}
    if(!janijName){navigate(-1)}
    
    
    const refresh = async() => {
      fetchPersonalDatas(true)
      fetchAttendancesDatas()
      fetchPaymentsDatas()
    }
// Agregar un efecto para actualizar windowWidth en redimensionamientos
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Suscribirse al evento de redimensionamiento
    window.addEventListener('resize', handleResize);

    // Desuscribirse del evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    let initialFieldsState: JanijDTO = {
      name: "",
      group: "",
      birthday: "",
      nationalId: "",
      address: "",
      email: "",
      cellphone: "",
      school: "",
      notes: "",
      mother: {
        name: "",
        cellphone: "",
        email: "",
      },
      father: {
        name: "",
        cellphone: "",
        email: "",
      },
    };

    
    const [fields, setFields] = useState(initialFieldsState);
    async function fetchPersonalDatas(reload:boolean = false ) {
        setLoaded(false)
        setErrorDatosPersonales('')
        setViewDataPersonalDates([null])
        
        try {
          if(!janijName){throw new Error("No se especificó el janij")}
          const janijData = await getEditJanijData(janijName,reload)
          const fieldsFethced: any = {
            name: janijData.janijData.name,
            group: janijData.janijData.group,
            birthday: janijData.janijData.birthday,
            nationalId: janijData.janijData.nationalId,
            address: janijData.janijData.address,
            email: janijData.janijData.email,
            cellphone: janijData.janijData.cellphone,
            school: janijData.janijData.school,
            notes: janijData.janijData.notes,
            mother: {
              name: janijData.janijData.mother?.name,
              cellphone: janijData.janijData.mother?.cellphone,
              email: janijData.janijData.mother?.email,
            },
            father: {
              name: janijData.janijData.father?.name,
              cellphone: janijData.janijData.father?.cellphone,
              email: janijData.janijData.father?.email,
            },
          };
          setViewDataPersonalDates(janijData)
          setFields({...fieldsFethced})
          setNotEditedFields({...fieldsFethced})
          setLoaded(true)
          setFirstLoad(true);
        } catch (error:any) {
            setLoaded(true)
            console.error(error.message);
            setErrorDatosPersonales(error.message)
          }
        }

    
        async function fetchAttendancesDatas() {
          setErrorAsistencia("")
          setViewDataAttendances(null)
          try {
            if(!janijName){return}
              
            const attendances = await getAttendanceByNames(janijName);
            setViewDataAttendances(attendances)
          
           } catch (error:any) {
              setLoaded(true)
              console.error(error.message);
              setErrorAsistencia(error.message)
            }
          }
        async function fetchPaymentsDatas() {
          setErrorAsistencia("")
          setViewDataPayments(null)
          setPaymentsDatas(initialDatesPayments)
          setLoadedMessagePayments(false)
          setErrorPagos("")
          setErrorMessagePagos("")
          try {
            if(!janijName){throw new Error("/No se proporciono un janij valido")}
            const from = formatDateMonthUsToStringMonthEs(paymentsDatas.from)
            const to = formatDateMonthUsToStringMonthEs(paymentsDatas.to)
            const type = paymentsDatas.type
            const payments = await getMessageDebtByName(janijName,from,to,type);
            setViewDataPayments(payments)
            setLoadedMessagePayments(true)
            setViewDataPayments(payments)
            setLoadedMessagePayments(true)
          } catch (error:any) {
              setLoaded(true)
              console.error(error.message);
              if(error.message === "/No tiene deudas"){
                setErrorMessagePagos(error.message)
              }else{
                setErrorPagos(error.message)
              }
              setLoadedMessagePayments(true)

            }
          }
        

        //codigo para copiar

        const copyTextToClipboard = (text:string) => {
          const textArea = document.createElement('textarea');
          textArea.value = text;
          document.body.appendChild(textArea);
          textArea.select();
        
          try {
            document.execCommand('copy');
            handleAvisoAlert({
              show: true,
              message: 'Texto copiado al portapapeles',
              status: 'success',
              delay: 3000
            });
          } catch (err) {
            console.error('Error al copiar al portapapeles:', err);
            handleAvisoAlert({
              show: true,
              message: 'Error al copiar al portapapeles',
              status: 'danger',
              delay: 3000
            });
          }
        
          document.body.removeChild(textArea);
        };  

        const handleCopyToClipboard = () => {
          const formattedData = formatData(fields);
          copyTextToClipboard(formattedData)
          // Puedes copiar formattedData al portapapeles aquí
        };

        const handleCopyToClipboardPayments = () => {
          const text = viewDataPayments.content
          copyTextToClipboard(text)
        };
        
        
        const formatData = (data: JanijDTO) => {
          const fieldNamesDictionary: { [key: string]: any } = {
            name: 'Nombre',
            group: 'Grupo',
            birthday: 'Cumpleaños',
            nationalId: 'DNI',
            address: 'Dirección',
            email: 'Email',
            cellphone: 'Celular',
            school: 'Escuela',
            notes: 'Nota',
            mother: {
              name: 'Nombre Madre',
              cellphone: 'Celular Madre',
              email: 'Email Madre',
            },
            father: {
              name: 'Nombre Padre',
              cellphone: 'Celular Padre',
              email: 'Email Padre',
            },
          };
        
          const formattedData: string[] = [];
        
          for (const field in fieldNamesDictionary) {
            if (field in data) {
              if (typeof fieldNamesDictionary[field] === 'object') {
                for (const subField in fieldNamesDictionary[field]) {
                  const fieldValue = data[field as keyof JanijDTO];
                  if (fieldValue) {
                    const subFieldValue = fieldValue[subField as keyof JanijDTO['mother']];
                    if (subFieldValue) {
                      formattedData.push(`${fieldNamesDictionary[field][subField]}: ${subFieldValue}`);
                    }
                  }
                }
              } else {
                const fieldValue = data[field as keyof JanijDTO];
                if (fieldValue) {
                  if(field === 'group'){
                    formattedData.push(`${fieldNamesDictionary[field]}: ${fieldValue.toString()}`);
                  }else if(field === 'birthday'){
                    formattedData.push(`${fieldNamesDictionary[field]}: ${formatDateUsToEs(fieldValue.toString())}`);
                  }else{
                    formattedData.push(`${fieldNamesDictionary[field]}: ${fieldValue}`);
                  }
                }
              }
            }
          }

          return formattedData.join('\n');
        };
        

        
        
        
        
        
        
    const goToBrothers = (janij:any)=>{
      navigate(`/janijim/${janij.name}`)
      window[`scrollTo`]({ top: 0, behavior: `smooth` })
      
    }
      
    useEffect(() => {
      fetchPersonalDatas()
      fetchAttendancesDatas()
      fetchPaymentsDatas()
    },[navigate])

    const editHandleChange = (e: any) => {
      let { name, value } = e.target;
      let [fieldName, subFieldName] = name.split(".");
      if (subFieldName) {
        setFields((prevState:any) => ({
          ...prevState,
          [fieldName]: {
            ...(prevState as any)[fieldName],
            [subFieldName]: value,
          },
        }));
      } else {
        setFields((prevState:any) => ({
          ...prevState,
          [name]: value,
        }));
      }
    };

    const updateRequest = async(e:any) => {
      e.preventDefault();
      setIsUpdating(true)
      try {
        if (isEmptyOrSpaces(fields.name)) {
          throw new Error('/Error al guardar')
        }
        
        
        
        const editedFields = compareObjects(notEditedFields, fields);

        let janijUpdated = {
          ...editedFields,
          name: notEditedFields?.name,
        };
    
        if (editedFields.name) {
          janijUpdated.newName = editedFields.name;
        }
		let didNotRename = false
        if (Object.keys(editedFields).length > 0) {
          const resUpdateJanij = await updateJanij(janijUpdated);
		  if (resUpdateJanij?.length) handleAvisoAlert({
			show: true,
			message: `Se han producido errores al editar: ${resUpdateJanij.join(",")}`,
			status: "danger",
			delay: 60000
		  });
		  if (resUpdateJanij.some((error: string) => error.includes("nombre"))) didNotRename = true
        }
        setJanijIsEditable(false)
        setIsUpdating(false);
		if (editedFields.name && !didNotRename) {
			navigate(`/janijim/${editedFields.name}`, { replace: true });
			localStorage.removeItem(`getJanijById-${janijName}`);
			localStorage.removeItem("getAllJanijim");
		} else refresh()
      } catch (error:any) {
        setLoaded(true)
        console.error(error.message);
        handleAvisoAlert({
          show: true,
          message: `Error al guardar datos: ${error.message}`,
          status: "danger",
          delay: 60000
        });
      }
    
    }


    const handleCancel =()=>{
      toggleJanijIsEditable()
      setFields({...notEditedFields})
    }

    
    
    const genericProps:any ={
      loaded,
      viewDataPersonalDates, 
      janijIsEditable,
      isUpdating,
      firstLoad
    }
  
    const handlePaymentsChange = (e:any) => {
      const {value,name} = e.target;
      setPaymentsDatas({...paymentsDatas, [name]:value})
    }
    const HandleSubmitPayments = async (e:any) => {
      setErrorMessagePagos("")
      setLoadedMessagePayments(false)
      try {
        if(!janijName){throw new Error("/No se proporciono un janij valido")}
        const from = formatDateMonthUsToStringMonthEs(paymentsDatas.from)
        const to = formatDateMonthUsToStringMonthEs(paymentsDatas.to)
        const type = paymentsDatas.type
        const payments = await getMessageDebtByName(janijName,from,to,type);
        setViewDataPayments(payments)
        setLoadedMessagePayments(true)
      } catch (error:any) {
          setLoadedMessagePayments(true)
          console.error(error.message);
          if(error.message === "/No tiene deudas" || error.message === "/No se recibió un pago el último sábado"){
            setErrorMessagePagos(error.message)
          }else{
            setErrorPagos(error.message)
          
          }
        }
    }
  

    return (
        <main>
          <div className="container container-responsive">
          

          <HeaderPage title={
                  <div className="row d-flex justify-content-center align-items-center ">
                    <button type="button" title='Volver' className="btn btn-danger col-auto" onClick={() => navigate(-1)}><i className=" fas fa-arrow-left"></i></button>
                    <h2 className='col-auto my-auto text-nowrap fw-bold'>{janijName}</h2>
                  </div>
                }>
            <Button
              color="danger"
              title="Actualizar"
              onClick={refresh}
              type="button"
              className="mx-3"
            >
              <i className="fa fa-refresh"></i>
            </Button>
          </HeaderPage>
          <SectionContainer title="Datos Personales" classNameContainer="sectionContainerCustome" error={errorDatosPersonales}>
            
            <Form>
              <div className=" col-12 d-flex justify-content-between mb-2">
                <h5 className="my-auto">Información Janij</h5>
                <div className="my-auto">
                  <Button color="danger" disabled={janijIsEditable || !loaded} onClick={handleCopyToClipboard} className='me-2'><i className="fas fa-copy me-2" aria-hidden="true"/>Copiar</Button>
                  <Button color="danger" disabled={janijIsEditable || !loaded} onClick={toggleJanijIsEditable}><i className="fa-solid fa-pen-to-square me-2" aria-hidden="true"/>Editar</Button>
                </div>
              </div>
              <Row>
                <FormGroupCustom
                  name='name'
                  label='Nombre'
                  value={fields?.name}
                  onChange={editHandleChange}
                  col={'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'}
                  props={genericProps}
                />
                <FormGroupCustom
                  name="group"
                  type="select"
                  label="Grupo"
                  value={fields?.group}
                  onChange={editHandleChange}
                  col={'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'}
                  props={genericProps}
                >
                  {!(loaded && viewDataPersonalDates && viewDataPersonalDates["groups"]) ? (
                    <option disabled value="">
                      Cargando...
                    </option>
                  ) : (
                    viewDataPersonalDates["groups"]?.map((group: GroupDTO, index: number) => (
                      <option key={index} value={group.name}>
                        {group.name}
                      </option>
                    ))
                  )}
				<option value="" disabled hidden>Seleccione...</option>
                </FormGroupCustom>

                <FormGroupCustom
                  name='birthday'
                  label='Cumple'
                  type='date'
                  value={fields?.birthday}
                  onChange={editHandleChange}
                  col={'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'}
                  props={genericProps}
                />
                <FormGroupCustom
                  name='address'
                  label='Dirección'
                  type='text'
                  value={fields?.address}
                  onChange={editHandleChange}
                  col={'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'}
                  props={genericProps}
                />
                <FormGroupCustom
                  name='school'
                  label='Escuela'
                  type='text'
                  value={fields?.school}
                  onChange={editHandleChange}
                  col={'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'}
                  props={genericProps}
                />
                <FormGroupCustom
                  name='nationalId'
                  label='DNI'
                  type='text'
                  value={fields?.nationalId}
                  onChange={editHandleChange}
                  col={'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'}
                  props={genericProps}
                />
                <FormGroupCustom
                  name='email'
                  label='Email Janij'
                  type='text'
                  value={fields?.email}
                  onChange={editHandleChange}
                  col={'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'}
                  props={genericProps}
                />
                <FormGroupCustom
                  name='cellphone'
                  label='Celular Janij'
                  type='text'
                  value={fields?.cellphone}
                  onChange={editHandleChange}
                  col={'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'}
                  props={genericProps}
                />
              </Row>

              <hr />
              <h5 className="d-none d-lg-block">Información Madre</h5>
              <Button color='' onClick={toggleCollapseMother} className="d-lg-none">
                <h5>
                  Información Madre{' '}
                  {isOpenColapseMother ? <i className="fas fa-chevron-up" /> : <i className="fas fa-chevron-down" />}
                </h5>
              </Button>
              <Collapse isOpen={isOpenColapseMother || !(windowWidth < 992)}>
                <Row>
                  <FormGroupCustom
                    name='mother.name'
                    label='Nombre'
                    value={fields?.mother?.name}
                    onChange={editHandleChange}
                    col={'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'}
                    props={genericProps}
                  />
                  <FormGroupCustom
                    name='mother.cellphone'
                    label='Celular'
                    value={fields?.mother?.cellphone}
                    onChange={editHandleChange}
                    col={'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'}
                    props={genericProps}
                  />
                  <FormGroupCustom
                    name='mother.email'
                    label='Email'
                    value={fields?.mother?.email}
                    onChange={editHandleChange}
                    col={'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'}
                    props={genericProps}
                  />
                </Row>
              </Collapse>              

              <hr />
              <h5 className="d-none d-lg-block">Información Padre</h5>
              <Button color='' onClick={toggleCollapseFather} className="d-lg-none">
                <h5>
                  Información Padre{' '}
                  {isOpenColapseFather ? <i className="fas fa-chevron-up" /> : <i className="fas fa-chevron-down" />}
                </h5>
              </Button>
              <Collapse isOpen={isOpenColapseFather || !(windowWidth < 992)}>
                <Row>
                  <FormGroupCustom
                    name='father.name'
                    label='Nombre'
                    value={fields?.father?.name}
                    onChange={editHandleChange}
                    col={'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'}
                    props={genericProps}
                  />
                  <FormGroupCustom
                    name='father.cellphone'
                    label='Celular'
                    value={fields?.father?.cellphone}
                    onChange={editHandleChange}
                    col={'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'}
                    props={genericProps}
                  />
                  <FormGroupCustom
                    name='father.email'
                    label='Email'
                    value={fields?.father?.email}
                    onChange={editHandleChange}
                    col={'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'}
                    props={genericProps}
                  />
                </Row>
              </Collapse> 
              <hr />
              <FormGroupCustom
                name='notes'
                label='Nota'
                type='textarea'
                value={fields?.notes}
                onChange={editHandleChange}
                col={'col-12 '}
                style={{resize:'none'}}
                props={genericProps}
              />

              {janijIsEditable&&
              <div className="d-flex justify-content-center justify-content-md-end">
                <Button className="m-1" onClick={handleCancel} disabled={isUpdating}>
                Cancelar
                </Button>
                <Button
                  className="m-1"
                  color={isUpdating ? "warning" : "danger"}
                  disabled={
                    !(
                      loaded &&
                      viewDataPersonalDates &&
                      viewDataPersonalDates["groups"] &&
                      viewDataPersonalDates["janijData"]
                    ) ||
                    isUpdating ||
                    !firstLoad
                  }
                  onClick={updateRequest}
                >
                  {isUpdating ? (
                    <div>
                      Editando...{" "}
                      <Spinner animation="border" variant="light" size="sm" />
                    </div>
                  ) : (
                    'Guardar'
                  )}
                </Button>
              </div>
              }
            </Form>
            
            <hr />
            <h5>Hermanos</h5>
            <div className="d-flex flex-wrap">
              {viewDataPersonalDates.janijData?.siblings !== null && !viewDataPersonalDates.janijData?.siblings&& <Spinner color="danger"/>}
              {viewDataPersonalDates.janijData?.siblings && <>
                {viewDataPersonalDates.janijData?.siblings?.map((janij: any,index:number) =>(
                  <Button key={index} color="dark" className="m-1" onClick={()=>{goToBrothers(janij)}}>
                    {janij.name} <i className="fas fa-arrow-right" />
                  </Button>
                ))}
                </>
                }
              {(!viewDataPersonalDates.janijData?.siblings || viewDataPersonalDates.janijData?.siblings.length === 0) && loaded && <p>No tiene hermanos registrados</p>}
              
            </div>
            
          </SectionContainer>
          <SectionContainer title="Asistencias" classNameContainer="sectionContainerCustome" error={errorAsistencia} isCollapsed={isCollapsedAttendances} setIsCollapsed={setIsCollapsedAttendances}>
          {viewDataAttendances && viewDataAttendances.attendances && 
          viewDataAttendances.attendances.map((janij: any, index: number) => (
            <div key={index}>
              <h6 className='m-0'>
                <span className='fw-bold'>{janij.name}</span> ({janij?.group || "Sin grupo"})
              </h6>
              <ViewAttendances key={index} dates={viewDataAttendances?.dates} attendances={viewDataAttendances?.attendances[index]?.attendance} isCollapsed={isCollapsedAttendances} />
            </div>
          ))
        }


          {!viewDataAttendances  && janijName  && <>
            <h6 className='m-0'>
              <span className='fw-bold'>{janijName}</span>
              {viewDataAttendances?.attendances[0]?.group &&
              ` (${viewDataAttendances?.attendances[0]?.group})`} 
            </h6>
            <ViewAttendances dates={viewDataAttendances?.dates} attendances={viewDataAttendances?.attendances[0].attendance} isCollapsed={isCollapsedAttendances}/>
          </>}
          </SectionContainer>
          <SectionContainer title="Pagos" classNameContainer="sectionContainerCustome" error={errorPagos}>
            <div className='d-flex justify-content-center align-items-center'>
              
              <div className='col-12'>
                <h5>Mensaje de reclamo de pago</h5>

                <Row>
                  <Row className='m-auto justify-content-center text-center'>
                    <FormGroup className='col-md'>
                      <Label htmlFor='from'>Desde</Label>
                      <Input
                        id='from'
                        type='month'
                        min={initialDatesPayments.from}
                        max={initialDatesPayments.to}
                        disabled={!loadedMessagePayments}
                        name='from'
                        onChange={handlePaymentsChange}
                        value={paymentsDatas.from}/>
                    </FormGroup>
                    <FormGroup className='col-md'>
                      <Label htmlFor='to'>Hasta</Label>
                      <Input
                        id='to'
                        type='month'
                        min={initialDatesPayments.from}
                        max={initialDatesPayments.to}
                        disabled={!loadedMessagePayments}
                        name='to'
                        onChange={handlePaymentsChange}
                        value={paymentsDatas.to}/>
                    </FormGroup>
                    <FormGroup className='col-md'>
                      <Label htmlFor='Tipo'>Tipo</Label>
                      <Input
                        id='type'
                        type='select'
                        min={initialDatesPayments.from}
                        max={initialDatesPayments.to}
                        disabled={!loadedMessagePayments}
                        name='type'
                        onChange={handlePaymentsChange}
                        value={paymentsDatas.type}>
                          <option value='Deuda'>Deuda</option>
                          <option value='Deuda sin distribución'>Deuda sin distribución</option>
                          <option value='Deuda sin detalle'>Deuda sin detalle</option>
                          <option value='Comprobante'>Comprobante</option>
                      </Input>
                    </FormGroup>
                  </Row>
                  <Button
                    className='mb-3 w-50 mx-auto'
                    color={!loadedMessagePayments ? "warning" : "danger"}
                    disabled={!loadedMessagePayments}
                    onClick={HandleSubmitPayments}
                  >
                    {!loadedMessagePayments ? (
                      <div>
                        Calculando...{" "}
                        <Spinner animation="border" variant="light" size="sm" />
                      </div>
                    ) :"Calcular"}
                  </Button>
              
                  <Input
                  value={ errorMessagePagos?errorMessagePagos:viewDataPayments?viewDataPayments.content:'Cargando...'}
                  readOnly
                  type='textarea'
                  className="col-12"
                  style={{resize:'none', height: '15rem' }}
                />
                <Button onClick={handleCopyToClipboardPayments} className="btn btn-danger col-4 my-2 mx-auto ">
                  Copiar texto <i className="fas fa-copy"></i>
                </Button>
                </Row>
                
              </div>
              
              
              </div>
              
            
          </SectionContainer>


          </div>
      </main>
    );
}
