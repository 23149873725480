import axios from 'axios';
import { getCredentials } from '../auth/authUtils';
import { getDataFromCacheOrFetchLocalStorage } from './cacheServices';

export const getAllGroups = async (): Promise<{name: string, memberOf: string}[]> => {
    const cacheKey = "getAllGroups";

    const fetchFunction = async () => {
        const response:any = await axios(`${process.env.REACT_APP_BACKEND_DOMAIN}?action=groups&${getCredentials()}`);
        if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
        return response.data.content;
    };

    return getDataFromCacheOrFetchLocalStorage(cacheKey, fetchFunction,false,43800);
}
