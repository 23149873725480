import CreatableSelect from 'react-select/creatable';
import { removeAccentsBasic } from '../../../utils/strings';

function CreatableSelectSearch(props: any) {
    const customFilterOption = (option: any, rawInput: any) => {
        const words = removeAccentsBasic(rawInput.toLowerCase()).split(' ');

        return (
            option.data.__isNew__ ||
            words.every(word =>
                option.label
                    .toLowerCase()
                    .split(' ')
                    .some((labelWord:any) =>
                        removeAccentsBasic(labelWord).startsWith(removeAccentsBasic(word))
                    )
            )
        );
    };

    const formatCreateLabel = (inputValue: string) => `${props.label} "${inputValue}"`;

    const getOptions = (data: any) => {
        return data.map((row: any) => ({
            value: row.id,
            label: row[props.display],
            row
        }));
    };

    return (
        <CreatableSelect
            filterOption={customFilterOption}
            formatCreateLabel={formatCreateLabel}
            isDisabled={props.disabled}
            onChange={props.onChange}
            options={getOptions(props.data)}
            placeholder={props.placeholder}
            defaultInputValue={props.defaultValue}
        />
    );
}

export default CreatableSelectSearch;
