import axios from 'axios';
import { useState } from 'react';
import { Navigate, Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { Alert } from 'reactstrap';
import useAuth, { refreshToken } from './auth/authUtils';
import Navbar from './components/UI/Layout/HeaderNav';
import JanijDetails from './pages/janijDetails';
import './styles/App.css';
import Loading from './pages/misc/Loading';
import Attendance from './pages/Attendance';
import AttendanceList from './pages/AttendanceList';
import AttendanceSelectArea from './pages/AttendanceSelectArea';
import Janijim from './pages/Janijim';
import Login from './pages/Login';
import NotFound from './pages/misc/NotFound';

function App() {
  const { isLoading, isAuthenticated, user, signOut } = useAuth();
  axios.interceptors.response.use(async (response) => {
    if (response && !response?.data?.success && response?.data?.content?.type === "Unauthorized" && !response.config.url?.includes("action=refresh") && !response.config.url?.includes("action=login")) {
      const token = await refreshToken();
      if (!token) await signOut();
      if (!response.config.url) return response;
      const url = new URL(response.config.url);
      url.searchParams.set("token", token);
      response.config.url = url.toString();
      return axios(response.config);
    }
    return response;
  });
    
  const [avisoAlert, setAvisoAlert] = useState({show:false,message:"Default",status:"warning",extraMessage:null})
  const handleAvisoAlert = (props:any) =>{
    setAvisoAlert({...avisoAlert,...props})
    if(props.delay){
      setTimeout(() =>{
        setAvisoAlert({...avisoAlert,show:false})
      },props.delay)
    }
}
  if (isLoading) {
    return <Loading />;
  }

  function Layout() {
	return (
		<>
		  {isAuthenticated && <Navbar isLoading={isLoading} isAuthenticated={isAuthenticated} user={user} signOut={signOut}/>}
		  <div className="container">
		  	<Outlet />
		  </div>
		  {(avisoAlert.show)?
				<Alert
				className={`text-center fixed-bottom col-8 col-sm-7 col-md-5 col-lg-4 mx-auto alertCustom alert-${(avisoAlert.status)} `}
					>
					{(avisoAlert.extraMessage)?
					<div>{avisoAlert.message} {avisoAlert.extraMessage}</div>
					:<div>{avisoAlert.message}</div>}
				</Alert>
				:""}
		</>
	  );
	}

  const router = createBrowserRouter(
	createRoutesFromElements(
        <Route path="/" element={<Layout />}>
            <Route path="/" element={<Navigate to="/attendance" />} />
            <Route path="janijim" element={!isAuthenticated?<Login />:<Janijim handleAvisoAlert={handleAvisoAlert}/>} />
            <Route path="janijim/:janijName" element={!isAuthenticated?<Login />:<JanijDetails handleAvisoAlert={handleAvisoAlert}/>} />
            <Route path="attendance" element={!isAuthenticated?<Login />:<Attendance />} />
            <Route path="attendance/:date" element={!isAuthenticated?<Login />:<AttendanceSelectArea />} />
            <Route path="attendance/:date/:group" element={!isAuthenticated?<Login />:<AttendanceList handleAvisoAlert={handleAvisoAlert}/>} />
            <Route path="*" element={<NotFound />} />
        </Route>
	)
  );

  return <RouterProvider router={router} />;
}

export default App;