export const compareObjects = (initialState:any, updatedState:any) => {
    const editedFields:any = {};

    for (const key in initialState) {
      if (initialState[key] !== updatedState[key]) {
        editedFields[key] = updatedState[key];
      }
    }

    return editedFields;
  };

  export const compareArrayObjects = (initialArray: any[], updatedArray: any[]): any[] => {
    const editedElements: any[] = [];
  
    for (let i = 0; i < initialArray.length && i < updatedArray.length; i++) {
      const initialElement = initialArray[i];
      const updatedElement = updatedArray[i];
  
      let hasChanges = false;
  
      for (const key in initialElement) {
        // Verificar que los elementos existan y tengan la propiedad 'name' antes de comparar
        if (
          initialElement?.hasOwnProperty(key) &&
          updatedElement?.hasOwnProperty(key) &&
          initialElement[key] !== updatedElement[key]
        ) {
          hasChanges = true;
          break;
        }
      }
  
      if (hasChanges) {
        editedElements.push(updatedElement);
      }
    }
  
    return editedElements;
  };
